
@mixin button {
	@include button-fade;
	padding: 12px 15px;
	background: $btn-secondary;
	border: 2px solid $btn-primary;
	border-radius: 3px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	text-decoration: none;
	color: $btn-primary;
	justify-content: center;
    display: inline-flex;
    max-width: 275px;
	cursor: pointer;

    a {
        text-decoration: none;
        color: $btn-primary;
    }

    &:hover {
        a {
            color: $btn-secondary;
        }
    }
}

@mixin button-alt {
	@include button;
	@include button-alt-fade;

	background: $btn-alt-secondary;
	border: 2px solid $btn-alt-primary;
	color: $btn-alt-primary;

}

@mixin button-alt-fade {
	transition: all 0.3s ease;

	&:hover,
	&:focus,
	&:active {
		background-color: $btn-alt-primary;
		color: $btn-alt-secondary;
	}
}

// Fade button color on hover/focus/active
@mixin button-fade {
	transition: all 0.3s ease;

	&:hover,
	&:focus,
	&:active {
		background-color: $btn-primary;
		color: $btn-secondary;
	}
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
// Taken from Bootstrap4 here https://github.com/twbs/bootstrap/blob/c28934cb1871d6b6bd6a866660493a1137de17c6/scss/mixins/_breakpoints.scss
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
