@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#content {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;

    .svg {
        width: 100%;
        max-width: 100%;
    }

    #block-1 {
        padding-bottom: 72px;
        display: flex;
        flex-direction: column;

        .yellow-wave {
            margin-bottom: 40px;
        }

        #title {
            letter-spacing: 2px;
            padding: 16px;
            padding-top: 40px;
            font-size: 90px;
            font-family: 'Bree Serif', serif;
            color: $white;
            background: $yellow;
            margin-bottom: -1px;
        }

        img {
            max-width: 75%;
            margin: auto;
        }

        @include media-breakpoint-down(xl) {
            #title {
                font-size: 75px;
            }
        }

        @include media-breakpoint-down(md) {
            img {
                max-width: 100%;
                margin: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            #title {
                font-size: 50px;
            }
        }
    }

    #block-2 {
        font-family: 'Bree Serif', serif;
        font-size: 24px;
        padding: 24px 10%;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            @include button;
            font-size: 16px;
            margin-top: 24px;
            width: 200px;
        }
    }

    #menu {
        color: $menu-color;
        background: $menu-background;
        padding: 72px 0;
        display: flex;
        flex-direction: column;

        .menu-svg {
            background: $white;
            width: calc(100% + 1px);
            margin-left: -1px;
        }

        #top-menu-svg {
            margin-bottom: -1px;
        }

        #bottom-menu-svg {
            margin-top: -1px;
        }

        .menu-img-wrapper {
            background: $menu-img-background;
            z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 32px;

            .menu2 {
                margin-top: 54px;
                max-width: 75%;
            }
        }

        #title {
            font-size: 50px;
            font-family: 'Bree Serif', serif;
        }

        #subtitle {
            font-size: 14px;
        }
    }

    #story-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: 32px auto 96px auto;
        font-size: 20px;
        line-height: 1.5;
        padding: 32px;

        #story-title {
            font-family: 'Bree Serif', serif;
            font-size: 32px;
            margin-bottom: 16px;
        }

        #story-mission {
            margin: 32px 0;
        }
    }
}
