
$yellow: #ffcd3c;
$blue: #013FA6;
$grey: #3C3E3C;
$white: #FCFBFC;
$light-blue: #118AB2;
$orange: #EF476F;

$sm: 540px;
$md: 720px;
$lg-md: 900px;
$lg: 960px;
$xl: 1140px;
$xxl: 1320px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg-md: 900px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$btn-primary: $blue;
$btn-secondary: $white;

$btn-alt-primary: $orange;
$btn-alt-secondary: $white;

$menu-color: $orange;
$menu-background: $white;
$menu-img-background: $orange;

$nav-hover: $light-blue;
$footer-hover: $light-blue;
