@import 'scss/variables.scss';
@import 'scss/mixins.scss';

.menu-title {
    font-family: 'Bree Serif', serif;
    color: $white;
    font-weight: initial;
    font-size: 30px;
    margin-top: 54px;
}

.menu {
    width: 75%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 64px;

        .menu-item-img {
            max-width:140px;
            max-height: 200px;
            height: auto;
            width:auto;
            -o-object-fit: contain;
            object-fit: contain;
            margin-bottom: auto;
            margin-top: auto;
        }

        .menu-item-name {
            margin-top: 16px;
            font-size: 18px;
            font-family: 'Roboto', sans-serif;
            text-transform: capitalize;
            color: $white;
        }
    }
}

@include media-breakpoint-down(md) {
    .menu {
        width: 100%;

        .menu-item {
            margin: 24px 12px;
            width: 140px;
            height: 240px;

            .menu-item-img {
                max-width:120px;
                max-height: 180px;
            }
        }
    }
}
