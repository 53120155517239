@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#footer {
    font-family: 'Roboto', sans-serif;
	background: $grey;
	color: $white;
	display: flex;
	justify-content: space-around;
	align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: -1px;

    a {
        text-decoration: none;
        color: $white;

        &:hover {
            color: $footer-hover;
        }
    }

    #links {
        flex-direction: row;
        display: flex;

        .link-group {
            margin: 0 50px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .link-group-title {
                margin: 0;
                font-size: 16px;
                text-transform: uppercase;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 12px;

                li {
                    text-transform: uppercase;
                    margin: 10px;

                    color: $white;
                    cursor: pointer;

                    &:hover {
                        color: $footer-hover;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    #social {
        min-width: 252px;
        display: flex;

        justify-content: center;

        span {
            align-self: center;
        }

        a {
            font-size: 45px;
            margin: 0 16px;
            color: $white;

            &:hover {
                color: $footer-hover;
            }
        }
    }

    #trademark {
        min-width: 252px;
        text-align: center;
    }
}

@include media-breakpoint-down(xl) {
    #footer {
        flex-direction: column;
        padding-bottom: 60px;

        #links .link-group {
            margin: 0 20px;

            ul li {
                margin-left: 0;
            }
        }

        #social {
            margin: 50px 0;

            a {
                margin: 0 16px;
            }
        }

        #powered {
            margin-top: 30px;
        }
    }
}
