@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#not-found-container {
    line-height: 1.5;
    width: 80%;
    margin: 75px auto;
    max-width: 1000px;
    font-family: 'Roboto', sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;

    h2 {
        font-family: 'Bree Serif', serif;
        text-align: center;
        margin: 16px 0;
        font-size: 34px;
    }

    #not-found-text {
        font-size: 22px;
        display: flex;
        max-width: 775px;
        text-align: center;

        a {
            margin-right: 8px;
        }
    }

    #logo {
        display: flex;
        justify-content: center;
        margin: 32px;

        img {
            max-width: 350px;
            max-height: 300px;
        }
    }
}
