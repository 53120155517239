@import 'scss/variables.scss';

#pricing {
    width: 80%;
    margin: 75px auto;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;

    h2 {
        font-family: 'Bree Serif', serif;
        margin: 0;
        font-size: 40px;
    }

    .pricing-section {
        font-size: 18px;
        line-height: 1.5;
        margin: 40px 0;

        .pricing-text {
            font-style: italic;
            margin-top: 12px;
        }

        .pricing-text-additional {
            margin-top: 15px;
        }
    }

    #disclaimers-wrapper {
        div {
            margin: 30px 0;
            font-size: 18px;
        }
    }

    h3 {
        margin-bottom: 10px;
    }
}
