@import "scss/mixins.scss";
@import "scss/variables.scss";

html, body {
    height: 100%;
    font-size: 16px;
}

body {
    display: flex;
    margin: 0;
    padding: 0;
    background: $white;
    color: $grey;

    *:focus {
        outline: 2px solid $nav-hover;
    }
}

 @include media-breakpoint-down(lg-md) {
    body{
        -webkit-text-size-adjust: 100%;
    }
 }

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}
