@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#contact {
    line-height: 1.5;
    width: 80%;
    margin: 75px auto;
    max-width: 1000px;
    font-family: 'Roboto', sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;

    #logo {
        display: flex;
        justify-content: center;
        margin: 32px;

        img {
            max-width: 350px;
            max-height: 300px;
        }
    }

    #contact-text {
        font-size: 22px;
        display: flex;
        max-width: 775px;
        text-align: center;
        flex-direction: column;
    }

    #contact-number {
        margin-top: 32px;
    }

    #submitted-text {
        font-size: 22px;
        margin-top: 80px;
        display: flex;
        text-align: center;
    }

    #contact-form {
        font-size: 18px;
        line-height: 1.5;
        margin-top: 20px;
        width: 100%;

        #error-message {
            background: $orange;
            color: $white;
            text-align: center;
            padding: 12px;
            margin-top: 8px;
            margin-bottom: 16px;
            border-radius: 4px;
            display: flex;
            flex: 1;
            justify-content: center;
            transition: all 500ms linear;
        }

        h2 {
            font-family: 'Bree Serif', serif;
            text-align: center;
            margin: 16px 0;
            font-size: 34px;
        }

        #contact-first-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .required-input-wrapper,
        .input-wrapper {
            margin: 20px 0;
            display: flex;
            flex-direction: column;

            select,
            input {
                padding: 6px;
                border: 1px solid $blue;
                border-radius: 4px;
                font-size: 18px;

                &:focus {
                    outline: none !important;
                    border: 1px solid $light-blue;
                    border-radius: 4px
                }
            }
        }

        .required-input-wrapper {
            label {
                &:before {
                    content: "*";
                    color: red;
                    margin-right: 3px;
                }
            }
        }

        #submit-btn {
            @include button-alt;
            width: 175px;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-top: 80px;
        }
    }
}

 @include media-breakpoint-down(lg-md) {
    #contact {
        #contact-form {
            #contact-first-row {
                margin: 20px 0;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
