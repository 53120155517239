@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#nav {
    height: 100px;
    min-height: 100px;
    background: $blue;
    display: flex;
    justify-content: center;
    align-items: center;;
    font-family: 'Montserrat', serif;

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        #nav-logo-image {
            max-width: 100px;
        }

        li {
            margin: 0 40px;
            font-size: 18px;
            text-transform: uppercase;

            color: $white;
            cursor: pointer;

            &:hover {
                color: $footer-hover;
            }

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $nav-hover;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    #nav ul li {
        margin: 0 20px;
        font-size: 16px;
    }
}

@include media-breakpoint-down(md) {
    #nav {
        display: none;
    }
}
