
#sitemap-container {
    line-height: 1.5;
    width: 80%;
    margin: 75px auto;
    max-width: 1000px;
    font-family: 'Roboto', sans-serif;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;

    .link {
        font-size: 22px;
        display: flex;
    }
}
