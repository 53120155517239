@import 'scss/variables.scss';
@import 'scss/mixins.scss';

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 46px;
}

 .bm-burger-bars {
    background: $white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: $nav-hover;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: $white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: $blue;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: $blue;
}

/* Wrapper for item list */
.bm-item-list {
    color: $white;
    max-height: 500px;
    display: flex;
    font-size: 20px;
    font-family: 'Montserrat', serif;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Individual item */
.bm-item {
    margin: 15px;
    padding: 15px;
    text-transform: uppercase;
    color: $white;
    text-decoration: none;

    &:hover {
        color: $nav-hover;
    }

    &:focus {
        outline: 2px solid $white;
    }
}

#mobile-nav-wrapper {
    background: $blue;
    display: flex;
    justify-content: center;

    #mobile-nav-logo-image {
        max-width: 100px;
        margin: 16px;
    }
}

@include media-breakpoint-up(md) {
    #burger-nav, .bm-burger-button, #mobile-nav-wrapper {
        display: none;
    }
}
